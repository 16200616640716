jQuery( function ($) {

    $( document ).on( 'click touch', '.accordion-contents .content .c-title .toggle', function ( e ) {
        var theIcon = $( this ).children( 'i' );
        var theTarget = $( this ).data( 'target' );

        if ( theTarget ) {
            var targetDiv = $( '#' + theTarget );

            if ( !targetDiv.hasClass( 'active') ) {
                theIcon.removeClass( 'fa-plus');
                theIcon.addClass( 'fa-minus');
                targetDiv.addClass( 'active' );
            } else {
                theIcon.removeClass( 'fa-minus');
                theIcon.addClass( 'fa-plus');
                targetDiv.removeClass( 'active' );
            }
        }
    });

});