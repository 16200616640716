jQuery( function ($) {

    $( document ).on( 'click touch', '.yo-sidebar-toggle', function() {
        if ( ! $( this ).hasClass( 'active' ) ) {
            $( this ).addClass( 'active' );
            $( this ).children( 'i' ).removeClass( 'fa-caret-down' );
            $( this ).children( 'i' ).addClass( 'fa-caret-up' );
            $( this ).siblings( '.sidebar-container' ).addClass( 'active' );
        } else {
            $( this ).removeClass( 'active' );
            $( this ).children( 'i' ).removeClass( 'fa-caret-up' );
            $( this ).children( 'i' ).addClass( 'fa-caret-down' );
            $( this ).siblings( '.sidebar-container' ).removeClass( 'active' );
        }
    });
});